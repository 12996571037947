@import "../../assets/scss/components/base";

$component: componentClass("modale");

.#{$component} {
  padding: 0;
  margin: 0;
  & .modal-dialog {
   width: 100%;
  //  height: 100%;
   max-width: 100%;
   margin: auto;
   padding: 64px;
   pointer-events: auto;
   @include media-breakpoint-down(md){
    padding: 16px;
   }
  }
  .#{$component}__inner {
    max-width: 1088px;
    margin: auto;
  }

  .#{$component}__tab {
    margin-bottom: 64px;
    @include media-breakpoint-down(md){
      margin-bottom: 16px;
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;
    }
    overflow-x: auto;
    overflow-y: hidden;
       /* Hide scrollbar for IE, Edge and Firefox */
       -ms-overflow-style: none;  /* IE and Edge */
       scrollbar-width: none;  /* Firefox */
       &::-webkit-scrollbar {
         display: none;
       }
    &--list {
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md){
        justify-content: left;
      }
    }
    &--item {
      min-height: 52px;
      padding: 17px 24px;
      font-size: 16px;
      font-weight: 300;
      position: relative;
      & > span {
        & span {
          padding-left: 8px;
          white-space: nowrap;
        }

      }
      &::after {
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        background-color: transparent;
        transition: all .3s ease-out;
      }
      &:hover {
        cursor: pointer;
        & a {
          color: inherit;
        }
        &::after {
          background-color: tokenColor(set1,yellow1);
          transition: all .3s ease-out;
        }
      }

    }
  }

  .#{$component}__arrowtab {
    margin-bottom: 32px;
    & .arrow-left {
      & i {
        transform: rotate(180deg);
      }
    }
  }

  .#{$component}__content {
    margin-bottom: 64px;
  }

}
