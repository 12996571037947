$zaux-token-layout:(
  spacer-width-px: 8,
  spacers: (
    0: (
      mul: 0
    ),
    1: (
      mul: 0.5
    ),
    2: (
      mul: 1
    ),
    3: (
      mul: 1.5
    ),
    4: (
      mul: 2
    ),
    5: (
      mul: 3
    ),
    6: (
      mul: 4
    ),
    7: (
      mul: 6
    ),
    8: (
      mul: 8
    ),
    9: (
      mul: 9
    ),
    10: (
      mul: 10
    ),
    11: (
      mul: 11
    ),
    12: (
      mul: 12
    ),
    14: (
      mul: 16
    ),
    15: (
      mul: 18
    ),
    16: (
      mul: 24
    ),
    20: (
      mul: 27
    ),
    24: (
      mul: 30
    ),
    6b: (
      mul: 5
    ),
    7b: (
      mul: 7
    )
  ),
  container-max-widths: (
    s: 906px,
    m: 960px,
    l: 1250px
  ),
  grid-gutter-width-px: 16,
  gutters: (
    0: (
      mul: 0
    ),
    1: (
      mul: 1
    ),
    2: (
      mul: 2
    ),
    3: (
      mul: 3
    ),
    4: (
      mul: 4
    )
  ),
  zindex: (
    offcanvas: 110,
    header: 100,
    navmain: 90,
    modal: 80,
    sticky: 70,
    content: 20,
    overlay: 15
  )
);
