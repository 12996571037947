$zaux-token-colors:(
  default: (
    white: #FFFFFF,
    black: #333333,
    red: #d9534f,
    green: #5cb85c,
    blue: #337ab7,
    magenta: #FF00FF,
    orange: #f0ad4e,
    warning: #f0ad4e,
    error: #d9534f
  ),
  app: (
    theme: #005AAA
  ),
  set1: (
    darkbrown: #3D4240,
    lightblue: #EBF0EE,
    yellow1: #C8B17D,
    darkgreen: #243B35,
    white1: #FFFFFF,
    blackgreen: #1C1F1E
  )
);
