@charset "UTF-8";

@import "../../assets/scss/components/tokens/textblock.style";

$component: componentClass("textblock");
$cmp-tokens: ();

@if (variable-exists(zaux-textblock-style-tokens)){
    $cmp-tokens : $zaux-textblock-style-tokens;
}

.#{$component} {

&__listing {
  &--item {
    min-height: 52px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(sm){
      flex-direction: row;
      align-items: center;
    }
    &--text {
      display: flex;
      align-items: center;
    }

    &--download {
      display: flex;
      max-height: 51px;
      justify-content: end;

    }
  }
}
&__img {
  @include media-breakpoint-down(md) {
    margin: 0 -16px;
  }
}
}
