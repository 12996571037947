@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {

  border: 1px solid tokenColor(set1,yellow1);
  &__img {
    border-bottom: 1px solid tokenColor(set1,yellow1);
  }
  &__content--info {
    color: tokenColor(set1,darkbrown);
  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

