@charset "UTF-8";

$component: componentClass("imgblock");

.#{$component} {

  &__img {
    @include media-breakpoint-down(md) {
      margin: 0 -16px;
    }
  }

}
