.wpcf7-not-valid-tip{
    padding-top:tokenSpacer(2);
}

.wpcf7-response-output{
    border:none !important;
    padding: tokenSpacer(3) !important;
    background-color: tokenColor("default", "warning");
    color: tokenColor("set1","white1") !important;
}

.wpcf7-list-item.last {
  margin-left: 15px !important;
}

.wpcf7-list-item {
  &-label {
    vertical-align: top;
    padding-left: 4px;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
  }
}
