@import "../../assets/scss/components/base";

$component: componentClass("modale");

.#{$component} {

  background-color: #1C1F1ED9;
  backdrop-filter: blur(30px);

  .#{$component}__tab {
    &--item {
      border-bottom: 1px solid tokenColor(set1,yellow1);
      &.active {
        color: tokenColor(set1,yellow1);
        &::after {
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          background-color: tokenColor(set1,yellow1);
          transition: all .3s ease-out;
        }
      }
    }
  }

}
