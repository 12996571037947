@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock1");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock1-style-tokens)){
    $cmp-tokens : $zaux-displayblock1-style-tokens;
}

.#{$component} {
  &--post {
    margin-top: 120px;
    @include media-breakpoint-down(md){
      margin-top: 72px;
    }
  }

}
