@charset "UTF-8";

@import "../../assets/scss/components/tokens/menu.style";

$component: componentClass("menu");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.zaux-menu-active {
  overflow: hidden;
}

.#{$component} {
  display: none;
  &--var1.open {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 68px;
      z-index: 90;

      &__btn--close {
        padding: 0 24px;
        line-height: 52px;
        font-size: 16px;
        font-weight: 300;
      }

      & .#{$component}__inner {
        overflow-y: auto;
        height: 100%;
        width: 100%;
      }
    & .#{$component}__nav {
      &--list {
      }
      &--item {
        padding-bottom: 18px;
        margin-bottom: 64px;
        padding-left: 24px;
        & a {
          font-weight: 300;
          &:hover {
            & span {
              text-decoration: underline;
            }
          }
        }
      }
    }

    & .#{$component}__infobtn {
      display: flex;
      justify-content: flex-end;
    }

  }

}

