$zaux-token-typo:(
  base-font-size: 16px,
  breakpoints: (sm, md, lg, xl),
  sizes: (
    3: (
      fs: 11px,
      lh: 16px
    ),
    6: (
      fs: 16px,
      lh: 21px
    ),
    9: (
      fs: 24px,
      lh: 32px
    ),
    12: (
      fs: 36px,
      lh: 36px
    ),
    15: (
      fs: 54px,
      lh: 54px
    ),
    18: (
      fs: 81px,
      lh: 107px
    )
  )
);
