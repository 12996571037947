@charset "UTF-8";

$component: componentClass("modsection4");


$form-select-arrow : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#FFFFFF' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
.#{$component} {

  &__form {

   & .c-form__select-modale {
    border-bottom: 1px solid tokenColor(set1,yellow1);

    & select {
      background-color: transparent;
      color: white;
      background-image: escape-svg($form-select-arrow);
      background-position: right 0 top 5px;
      background-repeat: no-repeat;
      background-size: 16px;
      outline: none;
      & option {
        color: tokenColor(set1,darkbrown);
      }
    }
   }
  }
}

